// Import Fuse core library
@use '@angular/material' as mat;
@use "@dhutaryan/ngx-mat-timepicker/_index.scss" as mat-timepicker;
//import our desgin system 
@use "design-system/scss/core.scss" as aep;
@use "_theme-colors.scss" as theme-colors;

// Import breakpoints first since other files depend on them
@import "@fuse/scss/mixins/breakpoints";
@import "@fuse/scss/theming";

// Then import other utilities
@import "@fuse/scss/partials/global";
@import "@fuse/scss/partials/helpers";
@import "@fuse/scss/partials/colors";
@import "@fuse/scss/partials/reset";
// @import "@fuse/scss/partials/normalize";
@import "@fuse/scss/partials/scrollbars";
// @import "@fuse/scss/partials/icons";
// @import "@fuse/scss/partials/material";
// @import "@fuse/scss/partials/angular-material-fix";
// @import "@fuse/scss/partials/typography";
@import "@fuse/scss/partials/docs";
@import "@fuse/scss/partials/page-layouts";
@import "@fuse/scss/partials/cards";
@import "@fuse/scss/partials/navigation";
// @import "@fuse/scss/partials/forms";
@import "@fuse/scss/partials/toolbar";
@import "@fuse/scss/partials/print";

// Plugins
@import "@fuse/scss/partials/plugins/plugins";


// Import other styles
@import "angular-calendar/scss/angular-calendar";

// Import fonts
@import url("https://fonts.googleapis.com/css?family=Tajawal&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@600;700&display=swap');

// Import material-icons
@import "styles/material-icons";

body {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

// :root {
//     // --mat-protected-button-icon-offset: 8px;
//     // --mat-protected-button-icon-spacing: 8px;
//     // --mat-sys-primary: rgb(121, 61, 61);
//     // --mat-sys-surface-tint: rgb(96 86 144);
//     // --mat-sys-on-primary: rgb(255 255 255);
//     // --mat-sys-primary-container: rgb(230 222 255);
//     // --mat-sys-on-primary-container: rgb(72 63 119);
//     // --mat-sys-secondary: rgb(96 92 113);
//     // --mat-sys-on-secondary: rgb(255 255 255);
//     // --mat-sys-secondary-container: rgb(230 223 249);
//     // --mat-sys-on-secondary-container: rgb(72 68 89);
//     // --mat-sys-tertiary: rgb(143 76 52);
//     // --mat-sys-on-tertiary: rgb(255 255 255);
//     // --mat-sys-tertiary-container: rgb(255 219 207);
//     // --mat-sys-on-tertiary-container: rgb(114 53 31);
//     // --mat-sys-error: rgb(186 26 26);
//     // --mat-sys-on-error: rgb(255 255 255);
//     // --mat-sys-error-container: rgb(255 218 214);
//     // --mat-sys-on-error-container: rgb(147 0 10);
//     // --mat-sys-background: rgb(253 248 255);
//     // --mat-sys-on-background: rgb(28 27 32);
//     // --mat-sys-surface: rgb(253 248 255);
//     // --mat-sys-on-surface: rgb(28 27 32);
//     // --mat-sys-surface-variant: rgb(230 224 236);
//     // --mat-sys-on-surface-variant: rgb(72 69 78);
//     // --mat-sys-outline: rgb(121 117 127);
//     // --mat-sys-outline-variant: rgb(201 196 208);
//     // --mat-sys-shadow: rgb(0 0 0);
//     // --mat-sys-scrim: rgb(0 0 0);
//     // --mat-sys-inverse-surface: rgb(49 47 54);
//     // --mat-sys-inverse-on-surface: rgb(244 239 247);
//     // --mat-sys-inverse-primary: rgb(202 190 255);
//     // --mat-sys-primary-fixed: rgb(230 222 255);
//     // --mat-sys-on-primary-fixed: rgb(28 17 73);
//     // --mat-sys-primary-fixed-dim: rgb(202 190 255);
//     // --mat-sys-on-primary-fixed-variant: rgb(72 63 119);
//     // --mat-sys-secondary-fixed: rgb(230 223 249);
//     // --mat-sys-on-secondary-fixed: rgb(28 25 43);
//     // --mat-sys-secondary-fixed-dim: rgb(201 195 220);
//     // --mat-sys-on-secondary-fixed-variant: rgb(72 68 89);
//     // --mat-sys-tertiary-fixed: rgb(255 219 207);
//     // --mat-sys-on-tertiary-fixed: rgb(56 13 0);
//     // --mat-sys-tertiary-fixed-dim: rgb(255 181 155);
//     // --mat-sys-on-tertiary-fixed-variant: rgb(114 53 31);
//     // --mat-sys-surface-dim: rgb(221 216 224);
//     // --mat-sys-surface-bright: rgb(253 248 255);
//     // --mat-sys-surface-container-lowest: rgb(255 255 255);
//     // --mat-sys-surface-container-low: rgb(247 242 250);
//     // --mat-sys-surface-container: rgb(241 236 244);
//     // --mat-sys-surface-container-high: rgb(235 230 238);
//     // --mat-sys-surface-container-highest: rgb(230 225 233);
// }

// material global varibles: 
/**
  --mat-sys-dragged-state-layer-opacity: 0.16;
  --mat-sys-focus-state-layer-opacity: 0.12;
  --mat-sys-hover-state-layer-opacity: 0.08;
  --mat-sys-pressed-state-layer-opacity: 0.12;
  --mat-sys-corner-extra-large: 28px;
  --mat-sys-corner-extra-large-top: 28px 28px 0 0;
  --mat-sys-corner-extra-small: 4px;
  --mat-sys-corner-extra-small-top: 4px 4px 0 0;
  --mat-sys-corner-full: 9999px;
  --mat-sys-corner-large: 16px;
  --mat-sys-corner-large-end: 0 16px 16px 0;
  --mat-sys-corner-large-start: 16px 0 0 16px;
  --mat-sys-corner-large-top: 16px 16px 0 0;
  --mat-sys-corner-medium: 12px;
  --mat-sys-corner-none: 0;
  --mat-sys-corner-small: 8px;
  --mat-sys-body-large: 400 1rem / 1.5rem Roboto;
  --mat-sys-body-large-font: Roboto;
  --mat-sys-body-large-line-height: 1.5rem;
  --mat-sys-body-large-size: 1rem;
  --mat-sys-body-large-tracking: 0.031rem;
  --mat-sys-body-large-weight: 400;
  --mat-sys-body-medium: 400 0.875rem / 1.25rem Roboto;
  --mat-sys-body-medium-font: Roboto;
  --mat-sys-body-medium-line-height: 1.25rem;
  --mat-sys-body-medium-size: 0.875rem;
  --mat-sys-body-medium-tracking: 0.016rem;
  --mat-sys-body-medium-weight: 400;
  --mat-sys-body-small: 400 0.75rem / 1rem Roboto;
  --mat-sys-body-small-font: Roboto;
  --mat-sys-body-small-line-height: 1rem;
  --mat-sys-body-small-size: 0.75rem;
  --mat-sys-body-small-tracking: 0.025rem;
  --mat-sys-body-small-weight: 400;
  --mat-sys-display-large: 400 3.562rem / 4rem Open Sans;
  --mat-sys-display-large-font: Open Sans;
  --mat-sys-display-large-line-height: 4rem;
  --mat-sys-display-large-size: 3.562rem;
  --mat-sys-display-large-tracking: -0.016rem;
  --mat-sys-display-large-weight: 400;
  --mat-sys-display-medium: 400 2.812rem / 3.25rem Open Sans;
  --mat-sys-display-medium-font: Open Sans;
  --mat-sys-display-medium-line-height: 3.25rem;
  --mat-sys-display-medium-size: 2.812rem;
  --mat-sys-display-medium-tracking: 0;
  --mat-sys-display-medium-weight: 400;
  --mat-sys-display-small: 400 2.25rem / 2.75rem Open Sans;
  --mat-sys-display-small-font: Open Sans;
  --mat-sys-display-small-line-height: 2.75rem;
  --mat-sys-display-small-size: 2.25rem;
  --mat-sys-display-small-tracking: 0;
  --mat-sys-display-small-weight: 400;
  --mat-sys-headline-large: 400 2rem / 2.5rem Open Sans;
  --mat-sys-headline-large-font: Open Sans;
  --mat-sys-headline-large-line-height: 2.5rem;
  --mat-sys-headline-large-size: 2rem;
  --mat-sys-headline-large-tracking: 0;
  --mat-sys-headline-large-weight: 400;
  --mat-sys-headline-medium: 400 1.75rem / 2.25rem Open Sans;
  --mat-sys-headline-medium-font: Open Sans;
  --mat-sys-headline-medium-line-height: 2.25rem;
  --mat-sys-headline-medium-size: 1.75rem;
  --mat-sys-headline-medium-tracking: 0;
  --mat-sys-headline-medium-weight: 400;
  --mat-sys-headline-small: 400 1.5rem / 2rem Open Sans;
  --mat-sys-headline-small-font: Open Sans;
  --mat-sys-headline-small-line-height: 2rem;
  --mat-sys-headline-small-size: 1.5rem;
  --mat-sys-headline-small-tracking: 0;
  --mat-sys-headline-small-weight: 400;
  --mat-sys-label-large: 500 0.875rem / 1.25rem Roboto;
  --mat-sys-label-large-font: Roboto;
  --mat-sys-label-large-line-height: 1.25rem;
  --mat-sys-label-large-size: 0.875rem;
  --mat-sys-label-large-tracking: 0.006rem;
  --mat-sys-label-large-weight: 500;
  --mat-sys-label-large-weight-prominent: 700;
  --mat-sys-label-medium: 500 0.75rem / 1rem Roboto;
  --mat-sys-label-medium-font: Roboto;
  --mat-sys-label-medium-line-height: 1rem;
  --mat-sys-label-medium-size: 0.75rem;
  --mat-sys-label-medium-tracking: 0.031rem;
  --mat-sys-label-medium-weight: 500;
  --mat-sys-label-medium-weight-prominent: 700;
  --mat-sys-label-small: 500 0.688rem / 1rem Roboto;
  --mat-sys-label-small-font: Roboto;
  --mat-sys-label-small-line-height: 1rem;
  --mat-sys-label-small-size: 0.688rem;
  --mat-sys-label-small-tracking: 0.031rem;
  --mat-sys-label-small-weight: 500;
  --mat-sys-title-large: 400 1.375rem / 1.75rem Open Sans;
  --mat-sys-title-large-font: Open Sans;
  --mat-sys-title-large-line-height: 1.75rem;
  --mat-sys-title-large-size: 1.375rem;
  --mat-sys-title-large-tracking: 0;
  --mat-sys-title-large-weight: 400;
  --mat-sys-title-medium: 500 1rem / 1.5rem Roboto;
  --mat-sys-title-medium-font: Roboto;
  --mat-sys-title-medium-line-height: 1.5rem;
  --mat-sys-title-medium-size: 1rem;
  --mat-sys-title-medium-tracking: 0.009rem;
  --mat-sys-title-medium-weight: 500;
  --mat-sys-title-small: 500 0.875rem / 1.25rem Roboto;
  --mat-sys-title-small-font: Roboto;
  --mat-sys-title-small-line-height: 1.25rem;
  --mat-sys-title-small-size: 0.875rem;
  --mat-sys-title-small-tracking: 0.006rem;
  --mat-sys-title-small-weight: 500;
  --mat-sys-level5: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-sys-level4: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-sys-level3: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-sys-level2: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-sys-level1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-sys-level0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-sys-background: #faf9fd;
  --mat-sys-error: #ba1a1a;
  --mat-sys-error-container: #ffdad6;
  --mat-sys-inverse-on-surface: #f2f0f4;
  --mat-sys-inverse-primary: #abc7ff;
  --mat-sys-inverse-surface: #2f3033;
  --mat-sys-on-background: #1a1b1f;
  --mat-sys-on-error: #ffffff;
  --mat-sys-on-error-container: #410002;
  --mat-sys-on-primary: #ffffff;
  --mat-sys-on-primary-container: #001b3f;
  --mat-sys-on-primary-fixed: #001b3f;
  --mat-sys-on-primary-fixed-variant: #00458f;
  --mat-sys-on-secondary: #ffffff;
  --mat-sys-on-secondary-container: #131c2b;
  --mat-sys-on-secondary-fixed: #131c2b;
  --mat-sys-on-secondary-fixed-variant: #3e4759;
  --mat-sys-on-surface: #1a1b1f;
  --mat-sys-on-surface-variant: #44474e;
  --mat-sys-on-tertiary: #ffffff;
  --mat-sys-on-tertiary-container: #00006e;
  --mat-sys-on-tertiary-fixed: #00006e;
  --mat-sys-on-tertiary-fixed-variant: #0000ef;
  --mat-sys-outline: #74777f;
  --mat-sys-outline-variant: #c4c6d0;
  --mat-sys-primary: #005cbb;
  --mat-sys-primary-container: #d7e3ff;
  --mat-sys-primary-fixed: #d7e3ff;
  --mat-sys-primary-fixed-dim: #abc7ff;
  --mat-sys-scrim: #000000;
  --mat-sys-secondary: #565e71;
  --mat-sys-secondary-container: #dae2f9;
  --mat-sys-secondary-fixed: #dae2f9;
  --mat-sys-secondary-fixed-dim: #bec6dc;
  --mat-sys-shadow: #000000;
  --mat-sys-surface: #faf9fd;
  --mat-sys-surface-bright: #faf9fd;
  --mat-sys-surface-container: #efedf0;
  --mat-sys-surface-container-high: #e9e7eb;
  --mat-sys-surface-container-highest: #e3e2e6;
  --mat-sys-surface-container-low: #f4f3f6;
  --mat-sys-surface-container-lowest: #ffffff;
  --mat-sys-surface-dim: #dbd9dd;
  --mat-sys-surface-tint: #005cbb;
  --mat-sys-surface-variant: #e0e2ec;
  --mat-sys-tertiary: #343dff;
  --mat-sys-tertiary-container: #e0e0ff;
  --mat-sys-tertiary-fixed: #e0e0ff;
  --mat-sys-tertiary-fixed-dim: #bec2ff;
  --mat-sys-neutral-variant20: #2d3038;
  --mat-sys-neutral10: #1a1b1f;
  --mdc-checkbox-state-layer-size: 36px;
  --mdc-chip-container-height: 28px;
  --mdc-text-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mdc-icon-button-state-layer-size: 36px;
  --mdc-list-list-item-one-line-container-height: 44px;
  --mdc-list-list-item-two-line-container-height: 60px;
  --mdc-list-list-item-three-line-container-height: 84px;
  --mdc-radio-state-layer-size: 36px;
  --mdc-secondary-navigation-tab-container-height: 44px;
  --mat-checkbox-touch-target-display: block;
  --mat-expansion-header-collapsed-state-height: 44px;
  --mat-expansion-header-expanded-state-height: 60px;
  --mat-fab-touch-target-display: block;
  --mat-form-field-container-height: 52px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 14px;
  --mat-form-field-filled-with-label-container-padding-top: 22px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
  --mat-icon-button-touch-target-display: block;
  --mat-list-list-item-leading-icon-start-space: 12px;
  --mat-list-list-item-leading-icon-end-space: 12px;
  --mat-text-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
  --mat-paginator-container-size: 52px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
  --mat-radio-touch-target-display: block;
  --mat-select-arrow-transform: translateY(-8px);
  --mat-standard-button-toggle-height: 40px;
  --mat-stepper-header-height: 68px;
  --mat-table-header-container-height: 52px;
  --mat-table-footer-container-height: 48px;
  --mat-table-row-item-container-height: 48px;
  --mat-toolbar-standard-height: 60px;
  --mat-toolbar-mobile-height: 52px;
  --mat-tree-node-min-height: 44px;
**/

body:has(div[data-theme='light']) {
    @include mat.theme((
    color: (
        theme-type: light,
        primary: theme-colors.$primary-palette,
        tertiary: theme-colors.$tertiary-palette,        
        ),
        typography: (
            plain-family: Poppins,
            brand-family: Open Sans,
        ),
        density: -1
    ));
    // @include theme-colors.high-contrast-overrides(light);   
    background-color: var(--mat-sys-surface);
}

body:has(div[data-theme='dark']) {
    @include mat.theme((
      color: (
        theme-type: dark,
        primary: theme-colors.$primary-palette,
        tertiary: theme-colors.$tertiary-palette,
      ),
      typography: (
        plain-family: Poppins,
        brand-family: Open Sans,
    ),
      density: -1
    ));
    // @include theme-colors.high-contrast-overrides(dark);
    background-color: var(--mat-sys-surface);


}

  
.mat-timepicker-content-layout-separator {
    width: none!important;
    height: 72px!important;
}
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.default-dialog {
    mat-dialog-container {
        padding: 24px !important;
    }
}

.cairo-text {
    font-family: Cairo;
    font-style: normal;
}

.cdk-global-scrollblock {
    overflow-y: auto !important;
}

// Keep only the Fuse color utility classes without Material theme overrides
.yellow-theme {
    // Basic styling for the yellow theme
    .accent {
        color: #ffd740;
    }
    
    .accent-bg {
        background-color: #ffd740;
    }
}

.bg-white {
    background-color: white;
    margin: 1px;
}

.cdk-global-overlay-wrapper {
    @include media-breakpoint("xs") {
        align-items: flex-end !important;
    }
}

.common-dialog-style {
    @include media-breakpoint("xs") {
        width: 100% !important;
        max-width: 100vw !important;
        height: 90vh !important;
        max-height: 90vh !important;

        .mat-mdc-dialog-actions.buttons {
            justify-content: center;
        }
    }

    @include media-breakpoint-up("xs") {
        width: auto;
    }

    .mat-mdc-dialog-container {
        padding: 0 !important;
        border-radius: 15px;
        height: fit-content;

        @include media-breakpoint("xs") {
            align-self: flex-end !important;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
        }
    }

    .dialog-content-wrapper {
        padding: 0% !important;
        margin: 0%;
        max-height: 90vh;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .primary-color-input,
    .secondary-color-input {
        padding: 6px 8px;
    }
}

.common-dialog-large-style {
    @include media-breakpoint("xs") {
        width: 100% !important;
        max-width: 100vw !important;
        height: 100vh !important;
        max-height: 100vh !important;
    }

    @include media-breakpoint-up("xs") {
        width: auto;
    }

    width: min(100vw, 800px);



}

.cal-week-view .cal-time-events {
    max-height: calc(100vh - 330px);
    overflow-y: auto;

    @media screen and (max-width:1000px) {
        max-height: calc(100vh - 280px);
    }
}



.cal-day-headers {
    margin-right: 14.5px;
}

.cal-day-columns {
    height: 100%;

}



mwl-calendar-month-cell,
mwl-calendar-day-view-hour-segment,
mwl-calendar-week-view-hour-segment {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}

.cal-event {
    -ms-touch-action: auto !important;
    touch-action: auto !important;
}

//.disabled-dark
.mat-mdc-select-disabled .mat-mdc-select-value {
    color: #039be5; // rgba(34, 15, 141, 0.74);
}

.material-icons.green {
    color: green;
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.material-icons.red {
    color: red;
}

.cal-event-title {
    white-space: pre-wrap;
}

.cal-day-view .cal-event {
    color: white;
    font-weight: 500;
}

.cal-week-view .cal-event {
    color: white;
    font-weight: 500;
}

.cal-month-view {
    .cal-header {
        .cal-cell {
            font-weight: 500;
        }
    }

    .cal-day-cell {
        height: 100;
        width: 170;

        @include media-breakpoint(lg) {
            min-height: 100px;
        }

        @include media-breakpoint(gt-lg) {
            min-height: 100px;
        }

        &.cal-open {
            @include mat.elevation(3);
        }
    }

    .cal-open-day-events {
        background: rgb(51, 110, 221);
        -webkit-box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.54);
        box-shadow: inset 0 0 12px 0 rgba(0, 0, 0, 0.54);
        padding: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        >div {
            padding: 0 16px;
            margin: 8px 16px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: start;
            -ms-flex-pack: start;
            justify-content: flex-start;
            background-color: white;
            @include mat.elevation(1);
            -webkit-transition: -webkit-box-shadow 300ms ease;
            transition: -webkit-box-shadow 300ms ease;
            -o-transition: box-shadow 300ms ease;
            transition: box-shadow 300ms ease;
            transition: box-shadow 300ms ease, -webkit-box-shadow 300ms ease;

            &:first-of-type {
                margin-top: 16px;
            }

            &:last-of-type {
                margin-bottom: 16px;
            }

            &:hover {
                @include mat.elevation(3);
            }

            .cal-event {
                top: 0;
                margin: 0;
            }

            mwl-calendar-event-title {
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;

                .cal-event-title {
                    display: block;
                    padding: 21px 24px;
                    line-height: 1;
                    text-decoration: none;
                    color: black;
                }
            }

            mwl-calendar-event-actions {
                .cal-event-actions {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;

                    .cal-event-action {
                        display: block;
                        line-height: 1;
                        padding: 8px;
                    }
                }
            }
        }
    }
}

.mat-mdc-cell {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.fail {
    background-color: #f44336 !important;
    color: #fff !important;
    border: 50%;
    border-radius: 50%;
}

.mat-mdc-row:hover {
    cursor: pointer;
    background-color: #ececee !important;
}

.cdk-global-overlay-wrapper:has(.borderless-dialog-style) {
    align-items: center !important;
}

.mat-mdc-tab,
.mat-mdc-button {
    letter-spacing: normal !important;
}

.mat-internal-form-field>label {
    margin-left: -5px !important;
}

.mat-mdc-checkbox .mat-internal-form-field {
    font-family: Roboto, Helvetica Neue, sans-serif !important;
    font-size: 16px !important;
}

// .mat-mdc-dialog-container .mat-mdc-dialog-content {
//     padding: 0 !important;
// }

.mdc-list-item__primary-text {
    width: 100%;
}

.mat-mdc-dialog-surface:has(app-set-location) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}
.mat-mdc-dialog-surface:has(app-scheduling-setting) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}
.mat-mdc-dialog-surface:has(app-planning-popup) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}
.mat-mdc-dialog-surface:has(app-paiement-popup) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}
.mat-mdc-dialog-surface:has(app-history-popup) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}
.mat-mdc-dialog-surface:has(app-csv-download-popup) {
    @media screen and (min-width: 599px) {
        border-radius: 20px !important;
    }
}

.mat-mdc-dialog-surface:has(app-errordialog) {
    @media screen and (min-width: 599px) {
        border-radius: 20px;
    }
}
.mat-mdc-dialog-surface:has(app-cashout-dailog) {
    @media screen and (min-width: 599px) {
        border-radius: 20px;
    }
}
.mat-mdc-dialog-surface:has(app-reclamation-dialog) {
    @media screen and (min-width: 599px) {
        border-radius: 20px;
    }
}

.mat-mdc-dialog-surface:has(app-changermotpasse) {
    padding: 10px 24px 0 24px !important;
}


 