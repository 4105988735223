

@mixin typography($style) {
    @if $style == 'display-large' {
      font-family: var(--mat-sys-display-large-font);
      font-weight: var(--mat-sys-display-large-weight);
      font-size: var(--mat-sys-display-large-size);
      letter-spacing: var(--mat-sys-display-large-tracking);
      line-height: var(--mat-sys-display-large-line-height);
    } @else if $style == 'display-medium' {
      font-family: var(--mat-sys-display-medium-font);
      font-weight: var(--mat-sys-display-medium-weight);
      font-size: var(--mat-sys-display-medium-size);
      letter-spacing: var(--mat-sys-display-medium-tracking);
      line-height: var(--mat-sys-display-medium-line-height);
    } @else if $style == 'display-small' {
      font-family: var(--mat-sys-display-small-font);
      font-weight: var(--mat-sys-display-small-weight);
      font-size: var(--mat-sys-display-small-size);
      letter-spacing: var(--mat-sys-display-small-tracking);
      line-height: var(--mat-sys-display-small-line-height);
    } @else if $style == 'headline-large' {
      font-family: var(--mat-sys-headline-large-font);
      font-weight: var(--mat-sys-headline-large-weight);
      font-size: var(--mat-sys-headline-large-size);
      letter-spacing: var(--mat-sys-headline-large-tracking);
      line-height: var(--mat-sys-headline-large-line-height);
    }@else if $style == 'headline-medium' {
      font-family: var(--mat-sys-headline-medium-font);
      font-weight: var(--mat-sys-headline-medium-weight);
      font-size: var(--mat-sys-headline-medium-size);
      letter-spacing: var(--mat-sys-headline-medium-tracking);
      line-height: var(--mat-sys-headline-medium-line-height);
    }@else if $style == 'headline-small' {
      font-family: var(--mat-sys-headline-small-font);
      font-weight: var(--mat-sys-headline-small-weight);
      font-size: var(--mat-sys-headline-small-size);
      letter-spacing: var(--mat-sys-headline-small-tracking);
      line-height: var(--mat-sys-headline-small-line-height);
    }@else if $style == 'title-large' {
      font-family: var(--mat-sys-title-large-font);
      font-weight: var(--mat-sys-title-large-weight);
      font-size: var(--mat-sys-title-large-size);
      letter-spacing: var(--mat-sys-title-large-tracking);
      line-height: var(--mat-sys-title-large-line-height);
    } @else if $style == 'title-medium' {
      font-family: var(--mat-sys-title-medium-font);
      font-weight: var(--mat-sys-title-medium-weight);
      font-size: var(--mat-sys-title-medium-size);
      letter-spacing: var(--mat-sys-title-medium-tracking);
      line-height: var(--mat-sys-title-medium-line-height);
    } @else if $style == 'title-small' {
      font-family: var(--mat-sys-title-small-font);
      font-weight: var(--mat-sys-title-small-weight);
      font-size: var(--mat-sys-title-small-size);
      letter-spacing: var(--mat-sys-title-small-tracking);
      line-height: var(--mat-sys-title-small-line-height);
    } @else if $style == 'body-large' {
      font-family: var(--mat-sys-body-large-font);
      font-weight: var(--mat-sys-body-large-weight);
      font-size: var(--mat-sys-body-large-size);
      letter-spacing: var(--mat-sys-body-large-tracking);
      line-height: var(--mat-sys-body-large-line-height);
    } @else if $style == 'body-medium' {
      font-family: var(--mat-sys-body-medium-font);
      font-weight: var(--mat-sys-body-medium-weight);
      font-size: var(--mat-sys-body-medium-size);
      letter-spacing: var(--mat-sys-body-medium-tracking);
      line-height: var(--mat-sys-body-medium-line-height);
    } @else if $style == 'body-small' {
      font-family: var(--mat-sys-body-small-font);
      font-weight: var(--mat-sys-body-small-weight);
      font-size: var(--mat-sys-body-small-size);
      letter-spacing: var(--mat-sys-body-small-tracking);
      line-height: var(--mat-sys-body-small-line-height);
    }@else if $style == 'label-large' {
      font-family: var(--mat-sys-label-large-font);
      font-weight: var(--mat-sys-label-large-weight);
      font-size: var(--mat-sys-label-large-size);
      letter-spacing: var(--mat-sys-label-large-tracking);
      line-height: var(--mat-sys-label-large-line-height);
    } @else if $style == 'label-medium' {
      font-family: var(--mat-sys-label-medium-font);
      font-weight: var(--mat-sys-label-medium-weight);
      font-size: var(--mat-sys-label-medium-size);
      letter-spacing: var(--mat-sys-label-medium-tracking);
      line-height: var(--mat-sys-label-medium-line-height);
    } @else if $style == 'label-small' {
      font-family: var(--mat-sys-label-small-font);
      font-weight: var(--mat-sys-label-small-weight);
      font-size: var(--mat-sys-label-small-size);
      letter-spacing: var(--mat-sys-label-small-tracking);
      line-height: var(--mat-sys-label-small-line-height);
    }
}
  
// Typography classes using the mixin
.display-large {
    @include typography('display-large');
}

.display-medium {
    @include typography('display-medium');
}

.display-small {
    @include typography('display-small');
}


.headline-large {
  @include typography('headline-large');
}

.headline-medium {
    @include typography('headline-medium');
}

.headline-small {
  @include typography('headline-small');
}



.title-large {
  @include typography('title-large');
}


.title-medium {
    @include typography('title-medium');
}

.title-small {
    @include typography('title-small');
}


.body-large {
    @include typography('body-large');
}

.body-medium {
    @include typography('body-medium');
}

.body-small {
    @include typography('body-small');
}


.label-large {
  @include typography('label-large');
}

.label-medium {
  @include typography('label-medium');
}

.label-small {
  @include typography('label-small');
}

body {
  font-family: var(--mat-sys-display-large-font);
}