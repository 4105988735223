// Page Layouts
@use '@angular/material' as mat;
$carded-header-height: 200px !default;
$carded-header-height-sm: 160px !default;
$carded-toolbar-height: 64px !default;

$header-height: 120px !default;
$header-height-sm: 100px !default;

// Calculate toolbarless header height
$carded-header-height-without-toolbar: $carded-header-height -
    $carded-toolbar-height;
$carded-header-height-without-toolbar-sm: $carded-header-height-sm -
    $carded-toolbar-height;

.page-layout {
    position: relative;
    overflow: hidden;

    // Carded layout
    &.carded {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;

        // Top bg
        > .top-bg {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            left: 0;
            height: $carded-header-height;

            @include media-breakpoint-down("sm") {
                height: $carded-header-height-sm;
            }
        }

        // Fullwidth
        &.fullwidth {
            // Center
            > .center {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1 0 auto;
                flex: 1 0 auto;
                position: relative;
                z-index: 2;
                padding: 0 32px;
                width: 100%;
                min-width: 0;
                max-width: 100%;
                max-height: 100%;

                > .header {
                    height: $carded-header-height-without-toolbar !important;
                    min-height: $carded-header-height-without-toolbar !important;
                    max-height: $carded-header-height-without-toolbar !important;

                    @include media-breakpoint-down("sm") {
                        height: $carded-header-height-without-toolbar-sm !important;
                        min-height: $carded-header-height-without-toolbar-sm !important;
                        max-height: $carded-header-height-without-toolbar-sm !important;
                    }
                }

                > .content-card {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 0 auto;
                    flex: 1 0 auto;
                    overflow: hidden;
                    @include mat.elevation(7);

                    > .toolbar {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: start;
                        -ms-flex-pack: start;
                        justify-content: flex-start;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        height: $carded-toolbar-height;
                        min-height: $carded-toolbar-height;
                        max-height: $carded-toolbar-height;
                    }

                    > .content {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 0 auto;
                        flex: 1 0 auto;
                    }
                }
            }

            // Tabbed
            &.tabbed {
                > .center {
                    width: 100%;
                    min-width: 0;

                    > .header {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                    }

                    > .content-card {
                        > .content {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;

                            > .mat-mdc-tab-group {
                                overflow: hidden;

                                .mat-mdc-tab-header {
                                    .mat-mdc-tab {
                                        height: 64px;
                                    }
                                }

                                .mat-mdc-tab-body {
                                    overflow: hidden;

                                    .mat-mdc-tab-body-content {
                                        overflow: hidden;

                                        .tab-content {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Inner scroll
            &.inner-scroll {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;

                > .center {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;

                    > .content-card {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        border-radius: 8px 8px 0 0;
                        > .content {
                            -webkit-box-flex: 1;
                            -ms-flex: 1 1 auto;
                            flex: 1 1 auto;
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;
                        }
                    }
                }

                // Tabbed
                &.tabbed {
                    > .center {
                        > .content-card {
                            > .content {
                                > .mat-mdc-tab-group {
                                    .mat-mdc-tab-body {
                                        .mat-mdc-tab-body-content {
                                            .tab-content {
                                                overflow: auto;
                                                -webkit-overflow-scrolling: touch;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Left / Right sidebar
        &.left-sidebar,
        &.right-sidebar {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;

            // Sidebar
            > .sidebar {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                height: auto;
                overflow: hidden;
                @include mat.elevation(7);

                &.locked-open {
                    background: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;

                    + .center {
                        z-index: 1001;
                    }

                    &.left-positioned {
                        + .center {
                            margin-left: 0;
                        }
                    }

                    &.right-positioned {
                        + .center {
                            margin-right: 0;
                        }
                    }
                }

                .header {
                    height: $carded-header-height;
                    min-height: $carded-header-height;
                    max-height: $carded-header-height;

                    @include media-breakpoint-down("sm") {
                        height: $carded-header-height-sm;
                        min-height: $carded-header-height-sm;
                        max-height: $carded-header-height-sm;
                    }
                }

                .content {
                    background: transparent;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                }
            }

            // Center
            > .center {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                position: relative;
                z-index: 3;
                margin-left: 32px;
                margin-right: 32px;
                min-width: 0;

                > .header {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    height: $carded-header-height-without-toolbar;
                    min-height: $carded-header-height-without-toolbar;
                    max-height: $carded-header-height-without-toolbar;

                    @include media-breakpoint-down("sm") {
                        height: $carded-header-height-without-toolbar-sm;
                        min-height: $carded-header-height-without-toolbar-sm;
                        max-height: $carded-header-height-without-toolbar-sm;
                    }
                }

                > .content-card {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                    overflow: hidden;
                    @include mat.elevation(7);

                    > .toolbar {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: start;
                        -ms-flex-pack: start;
                        justify-content: flex-start;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                        height: $carded-toolbar-height;
                        min-height: $carded-toolbar-height;
                        max-height: $carded-toolbar-height;

                        .sidebar-toggle {
                            margin: 0 8px 0 0 !important;
                            padding: 0 !important;
                            border-radius: 0;
                        }
                    }

                    > .content {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                    }
                }
            }

            // Tabbed
            &.tabbed {
                > .center {
                    width: calc(100% - 32px);
                    min-width: 0;

                    @include media-breakpoint-down("md") {
                        width: calc(100% - 64px);
                    }

                    > .header {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                    }

                    > .content-card {
                        > .content {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;

                            > .mat-mdc-tab-group {
                                overflow: hidden;

                                .mat-mdc-tab-header {
                                    .mat-mdc-tab {
                                        height: 64px;
                                    }
                                }

                                .mat-mdc-tab-body {
                                    overflow: hidden;

                                    .mat-mdc-tab-body-content {
                                        overflow: hidden;

                                        .tab-content {
                                            position: relative;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // Inner scroll
            &.inner-scroll {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;

                > .sidebar {
                    .content {
                        overflow: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                }

                > .center {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;

                    > .content-card {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;

                        > .content {
                            -webkit-box-flex: 1;
                            -ms-flex: 1 1 auto;
                            flex: 1 1 auto;
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;
                        }
                    }
                }

                // Tabbed
                &.tabbed {
                    > .center {
                        > .content-card {
                            > .content {
                                > .mat-mdc-tab-group {
                                    .mat-mdc-tab-body {
                                        .mat-mdc-tab-body-content {
                                            .tab-content {
                                                overflow: auto;
                                                -webkit-overflow-scrolling: touch;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Right sidebar specific
        &.right-sidebar {
            > .sidebar {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            > .center {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }
        }
    }

    // Simple layout
    &.simple {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        width: 100%;
        min-width: 100%;

        // Fullwidth
        &.fullwidth {
            > .content {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                min-width: 0;
            }
        }

        &.fullwidth,
        &.inner-sidebar {
            > .header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
            }
        }

        // Left / Right sidebar
        &.left-sidebar,
        &.right-sidebar {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;

            // Sidebar
            > .sidebar {
                width: 240px;
                min-width: 240px;
                max-width: 240px;
                overflow: hidden;
                @include mat.elevation(7);

                &.locked-open {
                    background: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;

                    + .center {
                        z-index: 1001;
                    }

                    &.left-positioned {
                        + .center {
                            margin-left: 0;
                        }
                    }

                    &.right-positioned {
                        + .center {
                            margin-right: 0;
                        }
                    }
                }

                .content {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                }
            }

            // Center
            > .center {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                z-index: 3;
                min-width: 0;
                @include mat.elevation(7);

                > .header {
                    height: $header-height;
                    min-height: $header-height;
                    max-height: $header-height;
                }

                > .content {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 0 auto;
                    flex: 1 0 auto;
                }
            }

            // Inner scroll
            &.inner-scroll {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;

                > .sidebar {
                    .content {
                        overflow: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                }

                > .center {
                    -webkit-box-flex: 1;
                    -ms-flex: 1 1 auto;
                    flex: 1 1 auto;
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                }
            }

            // Inner Sidebar
            &.inner-sidebar {
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                overflow: hidden;
                height: 100%;

                > .content {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    min-height: 0;

                    > .sidebar {
                        &.locked-open {
                            background: none;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                        }

                        .content {
                            overflow: auto;
                            -webkit-overflow-scrolling: touch;
                        }
                    }

                    > .center {
                        -webkit-box-flex: 1;
                        -ms-flex: 1 1 auto;
                        flex: 1 1 auto;
                        overflow: auto;
                        -webkit-overflow-scrolling: touch;
                    }
                }
            }
        }

        // Right sidebar specific
        &.right-sidebar {
            > .sidebar {
                -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                order: 2;
            }

            > .center {
                -webkit-box-ordinal-group: 2;
                -ms-flex-order: 1;
                order: 1;
            }

            // Inner sidebar
            &.inner-sidebar {
                > .content {
                    > .sidebar {
                        -webkit-box-ordinal-group: 3;
                        -ms-flex-order: 2;
                        order: 2;
                    }

                    > .center {
                        -webkit-box-ordinal-group: 2;
                        -ms-flex-order: 1;
                        order: 1;
                    }
                }
            }
        }

        // Tabbed
        &.tabbed {
            min-height: 100%;

            > .header {
                height: $header-height;
                min-height: $header-height;
                max-height: $header-height;
            }

            > .content {
                > .mat-mdc-tab-group {
                    .mat-mdc-tab {
                        padding: 0 24px;
                    }
                }
            }
        }
    }

    // Blank layout
    &.blank {
        width: 100%;
        min-height: 100%;
    }

    @include media-breakpoint-down("xs") {
        // Smaller margins
        &.carded {
            &.fullwidth {
                > .center {
                    padding: 0 16px;
                }
            }

            &.left-sidebar,
            &.right-sidebar {
                > .center {
                    margin: 0 16px;
                }
            }
        }
        // End - Smaller margins
    }
}
